import React, { useEffect, useRef } from "react";
import { Container } from "../Container";
import styled from "styled-components";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby";
import axios from "axios";

const QuestionnaireSection = styled.section`
  background-color: #f5f5f5;
`;

const QuestionnaireContainer = styled(Container)`
  width: 90%;
`;

const Header = styled.header`
  height: 10vh;
`;

const HeaderTitle = styled.h2`
  color: #666;
`;

const Form = styled.div`
  height: 80vh;
  margin-bottom: 10vh;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  box-sizing: border-box;
`;

export const Questionnaire = ({ id }) => {
  const container = useRef();

  const data = useStaticQuery(graphql`
    query TypeformQuery {
      site {
        siteMetadata {
          typeformFormId
        }
      }
    }
  `);

  const typeformFormId = data.site.siteMetadata.typeformFormId;

  useEffect(() => {
    createWidget(typeformFormId, {
      container: container.current,
      onSubmit: async ({ responseId }) => {
        try {
          const response = await axios.get("/.netlify/functions/getResponse", {
            params: { typeformReference: responseId },
          });
          const reference = response?.data?.reference;

          if (!reference) throw new Error("report not found");

          navigate(`/report/${reference}`);
        } catch (error) {
          console.error(error);
          navigate("/error");
        }
      },
    });
  }, [typeformFormId]);

  return (
    <QuestionnaireSection id={id}>
      <QuestionnaireContainer>
        <Header>
          <HeaderTitle>Take the test</HeaderTitle>
        </Header>
        <Form ref={container} />
      </QuestionnaireContainer>
    </QuestionnaireSection>
  );
};
