import React, { useState } from "react";
import { Container } from "../Container";
import styled from "styled-components";
import axios from "axios";
// import { useStaticQuery, graphql } from "gatsby";
import { Form, FormRow, FormInput, FormInputContainer, FormTextArea, FormSubmitButton } from "../Form";

const ContactSection = styled.section`
  background-color: #f5f5f5;
`;

const ContactContainer = styled(Container)`
  padding-bottom: 2rem;
  width: 90%;
`;

const Header = styled.h2`
  color: #444;
`;

const ContactForm = styled(Form)`
  flex: 1;
`;

const MessageFormRow = styled(FormRow)`
  flex: 1;
`;

export const Contact = ({ id, onEmailSend, setEmail, setMessage, setName }) => (
  <ContactSection id={id}>
    <ContactContainer>
      <header>
        <Header>Contact Us</Header>
      </header>
      <ContactForm>
        <FormRow>
          <FormInputContainer>
            <FormInput
              onChange={(event) => setName(event.target.value)}
              name="fullName"
              type="text"
              placeholder="Your Name"
            />
          </FormInputContainer>
          <FormInputContainer>
            <FormInput
              onChange={(event) => setEmail(event.target.value)}
              name="email"
              type="text"
              placeholder="Your Email"
            />
          </FormInputContainer>
        </FormRow>
        <MessageFormRow>
          <FormInputContainer>
            <FormTextArea
              onChange={(event) => setMessage(event.target.value)}
              name="message"
              placeholder="Your Message"
            />
          </FormInputContainer>
        </MessageFormRow>
        <FormRow centered>
          <FormInputContainer small>
            <FormSubmitButton onClick={onEmailSend} type="submit" value="Send Message" />
          </FormInputContainer>
        </FormRow>
      </ContactForm>
    </ContactContainer>
  </ContactSection>
);

export const ContactWrapper = ({ id }) => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const sendEmail = async () => {
    console.log({ message, senderEmail: email, senderName: name });
    try {
      await axios.post('/.netlify/functions/sendEmail', { message, senderEmail: email, senderName: name });
    } catch (error) {
      console.log("error: ", error?.message);
    }
  };

  return <Contact id={id} onEmailSend={sendEmail} setMessage={setMessage} setEmail={setEmail} setName={setName} />;
};
