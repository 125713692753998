import React from "react";
import { Container } from "../Container";

import styled from "styled-components";
import banner from "../../assets/images/banner.jpg";
import Scroll from "../Scroll";

const BannerSection = styled.section`
  background-size: cover;
  background-position: center center;
  background-color: #81918e;
  background-image: url(${banner});

  position: relative;
  text-align: center;

  color: rgba(255, 255, 255, 1);
`;

const BannerHeader = styled.h2`
  line-height: 2em;
  font-size: 2em;
  font-weight: 700;
`;

const GetStartedButton = styled.a`
  background: rgba(255, 255, 255, 0);
  border: 2px solid rgba(255, 255, 255, 1);
  text-decoration: none;
  padding: 0.75em 2em 0.75em 2em;
  color: rgba(255, 255, 255, 1);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:visited {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.2);
  }
`;

const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(80, 80, 80, 0.5);
`;

const BannerContainer = styled(Container)`
  z-index: 2;
  position: relative;
`;

export const Banner = (props) => (
  <BannerSection {...props}>
    <BannerOverlay />
    <BannerContainer>
      <header>
        <BannerHeader className="alt">MAKING WORK EQUAL</BannerHeader>
        <p>Benchmarking financial services firms for gender equality</p>
      </header>
      <footer>
        <Scroll type="id" element={"questionnaire"}>
          <GetStartedButton href="#questionnaire">Get Started</GetStartedButton>
        </Scroll>
      </footer>
    </BannerContainer>
  </BannerSection>
);
