import React from "react";
import { Container } from "../Container";
import styled from "styled-components";
import { CardsContainer, Card } from "../Card";

const AboutUsSection = styled.section`
  background-color: #f8f8f8;
`;

const AboutUsContainer = styled(Container)``;

const Header = styled.h2`
  color: #444;
`;

export const AboutUs = ({ id }) => (
  <AboutUsSection id={id}>
    <AboutUsContainer>
      <header>
        <Header>About Us</Header>
      </header>
      <CardsContainer>
        <Card
          title="Our Team"
          description="Our team is committed to improving gender equality in the work-place. We take our convictions and turn them into action. Get in touch if you'd like to join us!"
        />
        <Card
          title="Our History"
          description="Seein a need for improved gender benchmarking, we formed during our Executive MBA at INSEAD to provide sensible solutions, that measure, benchmark and inform firms how to take initiatives to improve gender balance."
        />
        <Card
          title="Our Mission"
          description="We focus on making the maximum positive effort for our community. Our members and volunteers provide the momentum that helps us affect change. Using data driven models, we provide solutions that make a long-lasting difference."
        ></Card>
      </CardsContainer>
    </AboutUsContainer>
  </AboutUsSection>
);
