import React from "react";
import styled from "styled-components";
import { Container } from "./Container";
import { down } from "styled-breakpoints";

const FooterContainer = styled(Container)`
  color: #888;
  font-size: 0.9rem;

  text-align: center;
  background-color: #505050;
  padding: 1em 0 2em 0;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05), inset 0 0.1em 0.1em 0 rgba(0, 0, 0, 0.025);
  height: auto;
  min-height: auto;
  width: 100%;

  ul {
    list-style: none;
  }

  cursor: default;
  margin: 0;

  li {
    display: inline-block;
    line-height: 1em;
    border-left: solid 1px rgba(128, 128, 128, 0.35);
    padding: 0 0 0 0.5em;
    margin: 0 0 0 0.5em;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }

  ${down("lg")} {
    li {
      display: block;
      line-height: 1.25em;
      border: 0;
      padding: 0;
      margin: 1em 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    text-decoration: none;
    color: #888;

    &:hover,
    &:visited {
      text-decoration: none;
      color: #888;
    }
  }
`;

export default function PageFooter() {
  return (
    <FooterContainer>
      <ul>
        <li>&copy; Gender Equality Benchmark. All rights reserved.</li>
        <li>
          Left Menu: <a href="http://html5up.net">HTML5 UP</a>
        </li>
        <li>
          Images: <a href="http://unsplash.com/">Unsplash</a>
        </li>
      </ul>
    </FooterContainer>
  );
}
