import styled from "styled-components";
import { down } from "styled-breakpoints";


export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};

  ${down("sm")} {
    flex-direction: column;
  }
`;

export const FormInputContainer = styled.div`
  flex: ${(props) => (props.small ? 0 : 1)};
  margin: 1rem 1rem 1rem 1rem;
  color: #888;
  display: flex;
`;

export const FormInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 4rem;
  font-size: 1.3rem;
  line-height: 1.75rem;
  position: relative;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 0.35em;
  padding: 0.75em 1em 0.75em 1em;
  box-shadow: inset 0 0.1em 0.1em 0 rgb(0 0 0 / 5%);
  border: solid 1px rgba(0, 0, 0, 0.15);
`;

export const FormTextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  font-size: 1.3rem;
  line-height: 1.2rem;
  position: relative;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 0.35em;
  padding: 0.75em 1em 0.75em 1em;
  box-shadow: inset 0 0.1em 0.1em 0 rgb(0 0 0 / 5%);
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
`;

export const FormSubmitButton = styled.input`
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 2rem;
  border-radius: 0.35em;
  color: #fff !important;
  text-decoration: none;
  padding: 0.75em 2.5em 0.75em 2.5em;
  background-color: #8ebebc;
  border: 0;
  cursor: pointer;

  ${down("sm")} {
    margin: auto;
  }
`;
