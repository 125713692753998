import React from "react";
import { Container } from "../Container";
import styled from "styled-components";
import { down } from "styled-breakpoints";


const ResourcesSection = styled.section`
  background-color: #f8f8f8;
`;

const ResourcesContainer = styled(Container)``;

const Header = styled.h2`
  color: #444;
`;

const ResourcesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const ResourceBody = styled.article`
  background: rgba(255, 255, 255, 1);
  padding: 3rem 3rem 0.5rem 3rem;
  margin: 1rem 1rem;
  box-shadow: 0 0.05em 0.15em 0 rgba(0, 0, 0, 0.05);
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ResourceHeader = styled.header`
  color: #444;
  text-align: left;
  height: 2rem;
  line-height: 1.75rem;
  font-size: 1.4rem;
  font-weight: bold;

  ${down("lg")} {
    margin-bottom: 1.75em;
  }
`;

const ResourceContent = styled.div`
  text-align: justify;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #666;
`;

export const Resources = ({ id }) => (
  <ResourcesSection id={id}>
    <ResourcesContainer>
      <header>
        <Header>Resources</Header>
      </header>
      <ResourcesList>
        <ResourceBody>
          <ResourceHeader>What is the gender balance in financial services?</ResourceHeader>
          <ResourceContent>
            <p>
              Oliver Wyman's report on "Woman in Financial Services" shows that the representation of women was only 20%
              at Executive Committees and 23% at boards in 2019.
            </p>
            <p>
              In the UK, the average pay gap in financial services rose to 23% in 2020, with a 38% pay gap in annual
              bonuses between men and women.
            </p>
            <p>
              In private equity, women represent only 29% of entry-level positions, and less than 10% of senior
              investment positions. An IFC survey in 2019 found that 70% of investment teamsa are all male.
            </p>
            <p>
              Although 67% of fund managers in one survey said achieving gender balance is important to their firm, less
              than 10% reported having strategies or targets in place for improving the promotion rate for femaile
              employees.
            </p>
          </ResourceContent>
        </ResourceBody>
        <ResourceBody>
          <ResourceHeader>What is a Gender Equality Strategy?</ResourceHeader>
          <ResourceContent>
            <p>
              A Gender Equality Strategy is a set of objectives and actions to make progress towards a gender-equal
              company culture and structure.
            </p>
          </ResourceContent>
        </ResourceBody>
        <ResourceBody>
          <ResourceHeader>How to build your dashboard?</ResourceHeader>
          <ResourceContent>
            <p>A dashboard is simple representation of the numbers that can be tracked over time.</p>
            <p>
              A dashboard can have the following components:
              <ul>
                <li>
                  <strong>Women representation statistics, </strong> overall, and by seniority level, by department /
                  job type, by country / region /office
                </li>
                <li>
                  <strong>OAT(Ormiston Academies Trust) gap statistics, </strong>overall, and by seniority level, by department / job type, by
                  country /office
                </li>
                <li>
                  <strong>Other relevant metrics: </strong>internal promotions, external hires, turnover rates
                </li>
              </ul>
            </p>
            <p>Source: INSEAD Gender Diversity Program</p>
          </ResourceContent>
        </ResourceBody>
        <ResourceBody>
          <ResourceHeader>How to calculate median gender pay-gap?</ResourceHeader>
          <ResourceContent>
            <p>
              The gender pay gap is calculated as the difference between average hourly earnings (excluding overtime) of
              men and women as a proportion of average hourly earnings (excluding overtime) of men's earnings.
            </p>
            <div>
              In simple terms:
              <ul>
                <li>take the average female earnings per month across all female employess in the firm</li>
                <li>divide by the average male earnings for all male employees</li>
                <li>calculate the difference as a percentage</li>
              </ul>
            </div>
          </ResourceContent>
        </ResourceBody>
      </ResourcesList>
    </ResourcesContainer>
  </ResourcesSection>
);
