import React from "react";
import { Container } from "../Container";
import styled from "styled-components";
import { CardsContainer, Card } from "../Card";

const OurServicesSection = styled.section`
  background-color: #f5f5f5;
`;

const OurServicesContainer = styled(Container)``;

const Header = styled.h2`
  color: #444;
`;

export const OurServices = ({ id }) => (
  <OurServicesSection id={id}>
    <OurServicesContainer>
      <header>
        <Header>Our Services</Header>
      </header>
      <CardsContainer>
        <Card
          title="Gender Equality Benchmark"
          description="Our gender equality benchmark is an easy-to-use rapid assesment tool. You can quickly assess how you are performing against your peers."
        />
        <Card
          title="Bespoke Insights"
          description="Opt-in to certification or receiving bespoke customized report and recommendations targeted at where your firm most needs to improve."
        />
        <Card
          title="Customized Consulting"
          description="We have a network of consultants that can help you reach due dilligence targets and improve on reaching gender equality targets."
        ></Card>
      </CardsContainer>
    </OurServicesContainer>
  </OurServicesSection>
);
