import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../Container";
import { Form, FormInput, FormInputContainer, FormRow } from "../Form";
import { down } from "styled-breakpoints";


const DonateSection = styled.section`
  background-color: #f5f5f5;
`;

const DonateContainer = styled(Container)`
  width: 50%;
  max-width: 750px;
  & > div {
    width: 100%;
  }
`;

const Header = styled.h2`
  color: #444;

  ${down("sm")} {
    text-align: center;
  }
`;

const PayPalFormInputContainer = styled(FormInputContainer)`
  flex: 1;
  z-index: 1;

  ${down("sm")} {
    align-self: center;
  }

  & > div {
    width: 100%;
    max-width: 750px;
  }
`;

const CurrencyInput = styled(FormInput)`
  min-width: 7rem;
  text-align: center;
`;

export const Donate = ({ id }) => {
  const [amount, setAmount] = useState("10");

  return (
    <DonateSection id={id}>
      <DonateContainer>
        <header>
          <Header>Make a Donation</Header>
        </header>
        <Form>
          <FormRow>
            <FormInputContainer>
              <FormInput
                onChange={(event) => {
                  if (event.target.value) {
                    setAmount(event.target.value);
                  }
                }}
                name="fullName"
                type="number"
                min="1"
                step="any"
                value={amount}
              />
            </FormInputContainer>
            <FormInputContainer small>
              <CurrencyInput name="currency" type="text" disabled value="USD" />
            </FormInputContainer>
          </FormRow>
          <FormRow>
            <PayPalFormInputContainer>
              <PayPalScriptProvider style={{ width: "100%" }} options={{ "client-id": "test" }}>
                <PayPalButtons
                  forceReRender={[amount]}
                  createOrder={(_, actions) =>
                    actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: amount,
                            currency: "USD",
                          },
                        },
                      ],
                    })
                  }
                  style={{
                    layout: "vertical",
                    label: "paypal",
                  }}
                />
              </PayPalScriptProvider>
            </PayPalFormInputContainer>
          </FormRow>
        </Form>
      </DonateContainer>
    </DonateSection>
  );
};
