import React from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 90%;

  ${down("lg")} {
    flex-direction: column;
  }
`;

const CardContainer = styled.article`
  background: rgba(255, 255, 255, 1);
  padding: 1rem 2.5rem;
  min-height: 250px;
  margin: 1rem 1rem;
  box-shadow: 0 0.05em 0.15em 0 rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardHeader = styled.header`
  color: #444;
  text-align: center;
  height: 4rem;
  line-height: 1.75rem;
  font-size: 1.3rem;
`;

const CardContent = styled.p`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #666;
`;

export const Card = ({ title, description }) => (
  <CardContainer>
    <CardHeader>
      <h3>{title}</h3>
    </CardHeader>
    <CardContent>{description}</CardContent>
  </CardContainer>
);
