import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "../Container";
import axios from "axios";
import { Form, FormInput, FormInputContainer, FormRow, FormSubmitButton } from "../Form";


const SubscribeSection = styled.section`
  background-color: #f8f8f8;
`;

const SubscribeContainer = styled(Container)`
  padding-bottom: 2rem;
  width: 60%;
  min-height: 250px;
`;

const Header = styled.h2`
  color: #444;
`;

const SubscribeForm = styled(Form)`
  flex: 1;
`;


export const Subscribe = ({ id }) => {
  const [subscriber, setSubscriber] = useState("");

  const addSubscriber = async () => {
    try {
      await axios.post('/.netlify/functions/addSubscriber', { subscriber });
    } catch (error) {
      console.log("error: ", error?.message);
    }
  };

  return (
    <SubscribeSection id={id}>
      <SubscribeContainer>
        <header>
          <Header>Subscribe</Header>
        </header>
        <SubscribeForm>
          <FormRow>
            <FormInputContainer>
              <FormInput
                onChange={(event) => setSubscriber(event.target.value)}
                name="email"
                type="text"
                placeholder="Your Email"
              />
            </FormInputContainer>
            <FormInputContainer small>
              <FormSubmitButton onClick={addSubscriber} type="submit" value="Subscribe" />
            </FormInputContainer>
          </FormRow>
        </SubscribeForm>
      </SubscribeContainer>
    </SubscribeSection>
  );
};
