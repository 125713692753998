import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import PageFooter from "../components/PageFooter";
import SideBar from "../components/SideBar";
import { Banner } from "../components/Content/Banner";
import { OurServices } from "../components/Content/OurServices";
import { AboutUs } from "../components/Content/AboutUs";
import { Donate } from "../components/Content/Donate";
import { Questionnaire } from "../components/Content/Questionnaire";
import { ContactWrapper } from "../components/Content/Contact";
import { Resources } from "../components/Content/Resources";
import { Subscribe } from "../components/Content/Subscribe";
import { up, down } from "styled-breakpoints";

const sections = [
  { id: "top", name: "Intro", icon: "fa-home" },
  { id: "our-services", name: "Our Services", icon: "fa-th" },
  { id: "resources", name: "Resources", icon: "fa-book" },
  { id: "questionnaire", name: "Take the test", icon: "fa-question" },
  { id: "about-us", name: "About Us", icon: "fa-user" },
  { id: "donate", name: "Donate", icon: "fa-heart" },
  { id: "subscribe", name: "Subscribe", icon: "fa-reply" },
  { id: "contact-us", name: "Contact", icon: "fa-envelope" },
];

const ContentContainer = styled.div`
  position: relative;
  ${up("md")} {
    margin-left: ${(props) => props.theme.sideBarWidth};
  }
  ${down("lg")} {
    margin-left: 0;
  }
`;

const SideBarCloser = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;

  ${up("md")} {
    display: none;
  }
`;

const IndexPage = () => {
  const [sideBarVisible, setSideBarVisible] = useState(false);

  return (
    <Layout>
      <SideBar
        sections={sections}
        onSideBarVisibleChange={(state) => setSideBarVisible(state)}
        sideBarVisible={sideBarVisible}
      />
      <ContentContainer sideBarVisible={sideBarVisible}>
        {sideBarVisible && (
          <SideBarCloser
            onClick={() => {
              setSideBarVisible(false);
            }}
          />
        )}
        <Banner id="top" />
        <OurServices id="our-services" />
        <Resources id="resources" />
        <Questionnaire id="questionnaire" />
        <AboutUs id="about-us" />
        <Donate id="donate" />
        <Subscribe id="subscribe" />
        <ContactWrapper id="contact-us" />
        <PageFooter />
      </ContentContainer>
    </Layout>
  );
};

export default IndexPage;
